import React, {useEffect, useState} from "react";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";

import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import EditHeaderComponent from "../../components/editHeader/EditHeaderComponent";
import Message from "semantic-ui-react/dist/commonjs/collections/Message";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import style from "./faq.module.scss";
import {getAllFaq} from "./FaqUtil";


const showFaqs = (faqs) => {
    return faqs.map((faq, index) =>
        faq.isActive === 1 ?
            <div itemScope itemProp="mainEntity"
                 itemType="https://schema.org/Question"
                 key={"faq" + index}>
                <p itemProp="name" className={style.faqQuestionContainer}><b>{faq.title}</b></p>
                <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                    <p className={style.faqAnswerContainer} itemProp="text">
                        {faq.text}
                    </p>
                </div>
            </div> : null
    )
};


const FAQPage = (props) => {

    let {i18n} = props;

    const [allFAQs, setAllFAQs] = useState([]);

    useEffect(() => {
        getAllFaq().then(faqs => {
            setAllFAQs(faqs);
        })
    }, []);

    const namespace = "Faq";
    let breadCrumbData = [];

    if (props.route && props.route.breadCrumbData) {
        breadCrumbData = props.route.breadCrumbData;
    }

    return (
        <Grid className={"background-primary text-primary"}>
            <Grid.Row centered>
                <Grid.Column computer={12} tablet={12} mobile={14}>
                    <BreadCrumbs breadCrumbData={breadCrumbData}/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row centered>
                <Grid.Column computer={12} tablet={12} mobile={14}>
                    <h1 className={"header-primary"}>
                        <EditHeaderComponent namespace={namespace} id={"faq-h1"}/>
                    </h1>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row centered>
                <Grid.Column computer={12} tablet={12} mobile={14}>
                    {
                        allFAQs.length !== 0 ?
                            <div itemScope itemType="https://schema.org/FAQPage">
                                {showFaqs(allFAQs)}
                            </div>
                            : <div>
                                <Message info style={{textAlign: "center"}}>
                                    <Message.Header>{i18n.t("faq:faq.faq")}</Message.Header>
                                    <p>{i18n.t("faq:faq.no_faq")}<Icon name={"frown outline"}/></p>
                                </Message>
                            </div>
                    }
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )

}

export default (FAQPage)