import config from "../../config/main.config";
import fetch from "node-fetch";



export function getFaq() {
    return new Promise(resolve => fetch(config.BASE_URL + "faq/", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        }
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                resolve(json);
            });
        } else {
            resolve([]);
        }
    }).catch(
        error => {
            resolve([]);
            console.log(error);
        }
    ));
}

export function getAllFaq() {
    return new Promise(resolve => fetch(config.BASE_URL + "faq/all", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        }
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                return resolve(json);
            });
        } else {
            resolve([]);
        }
    }).catch(
        error => {
            resolve([]);
            console.log(error);
        }
    ));
}


